@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Inter", sans-serif;
    scroll-behavior: smooth;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Poppins", sans-serif;
  }
}

/* Custom styles for better font rendering */
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

@keyframes ripple {
  0% {
    transform: translate(-50%, -50%) scale(0.3);
    opacity: 0.8;
  }
  100% {
    transform: translate(-50%, -50%) scale(4);
    opacity: 0;
  }
}

.animate-ripple {
  animation: ripple 8s linear infinite;
}

/* Optional: Add a subtle gradient overlay */
.ripple-effect::before {
  content: "";
  position: absolute;
  inset: 0;
  background: radial-gradient(
    circle at center,
    transparent 30%,
    rgba(139, 92, 246, 0.05) 70%
  );
  border-radius: 50%;
}

/* Magnetic button hover effect */
.magnetic-button {
  transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  will-change: transform;
}

.magnetic-button:hover {
  transform: scale(1.1);
}

/* Enhanced ripple effect */
.ripple-effect {
  transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  will-change: transform;
}

.ripple-effect:hover {
  transform: translate(-50%, -50%) scale(1.1);
}

/* Navbar styles */
.nav-link {
  position: relative;
}

.nav-link::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #7c3aed; /* Purple-600 */
  transition: width 0.3s ease;
}

.nav-link:hover::after {
  width: 100%;
}

/* Active link styles */
.nav-link.active::after {
  width: 100%;
}
